import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faGifts } from '@fortawesome/free-solid-svg-icons'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import clsx from 'clsx'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import BackToTopButton from '../components/BackToTopButton'
import AcademyExtras from '../components/AcademyExtras'
import Link from '../components/LinkWrapper'
import ScrollOnAnimation from '../components/ScrollOnAnimation'
import i18n from '../locales/he.yaml'
import { useVideo } from '../analytics'

const AcademyQuery = graphql`
  query AcademyQuery {
    academyHero: file(relativePath: { eq: "logo-academy-circle.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    safari: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגה-מעוצבת-סופר-גבוהה-מקושטת-בדפי-אורז-ועם-חיות-הספארי-אריה-ג׳ירפה-קוף-פיל-מפוסלים-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    rabbit: file(
      relativePath: { eq: "עוגה-מעוצבת-עם-פיסול-ארנב-בתלת-מימד-בבצק-סוכר.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    llama: file(
      relativePath: {
        eq: "לאמה-עומדת-בעלת-מבנה-של-4-רגליים-מפוסלת-בבצק-סוכר-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    butterflies: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגה-מעוצבת-סופר-גבוהה-מצופה-בטקסטורת-שוקולד-עם-פסלים-של-טירה-מפוסלים-מבצק-סוכר-ופרפרים-מנייר-אורז.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    circus: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגה-מעוצבת-סופר-גבוהה-מקושטת-בדפי-אורז-ועם-חיות-הקרקס-קוף-פיל-נמר-כלב-ים-מפוסלים-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
  }
`

const AcademyTemplate = ({ data, product }) => {
  const iframeRef = useVideo(product)

  const { safari, rabbit, llama, butterflies, circus } = data

  const PRODUCT_IMAGE = [safari, rabbit, llama, butterflies]

  return (
    <>
      <BackToTopButton selectorId='academy' />
      <div className='hero has-navbar-fixed-top'>
        <div className='hero-body is-padding-bottom-0'>
          <div className='container content has-text-centered'>
            <div className='columns is-centered'>
              <div className='column is-12-mobile is-10-tablet is-8-desktop'>
                <h1 className='is-size-4 has-text-nude'>
                  {i18n.academy.product.subtitle}
                </h1>
                <h2 className='is-size-4'>{i18n.academy.product.title}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container content'>
            <div className='columns is-multiline is-mobile is-centered is-vcentered'>
              <div className='column is-10-mobile is-8-tablet is-5-desktop'>
                <GatsbyImage
                  image={getImage(circus)}
                  title={i18n.academy.mainImage.title}
                  alt={i18n.academy.mainImage.alt}
                  className='live-image'
                  style={{
                    maxWidth: getImage(circus).width,
                    margin: '0 auto' // used to center the image
                  }}
                  loading='eager'
                />
                <div className='is-flex justify-center content'>
                  <p className='has-text-centered has-text-gold-black'>
                    {i18n.academy.mainImage.title}
                  </p>
                </div>
              </div>
              <div className='column is-6-desktop is-full-tablet is-full-mobile is-offset-1-desktop'>
                <h3 className='is-size-5'>{i18n.academy.title}</h3>
                <p>{i18n.academy.description}</p>
              </div>
            </div>
          </div>
        </div>
        <div className='hero-body has-text-centered content'>
          <Link to='/academy/courses/' className='button is-nude is-medium'>
            {i18n.academy.callToAction0}
          </Link>
          <p className='mt-4'>{i18n.academy.callToAction0Hint}</p>
        </div>
      </div>
      <ScrollOnAnimation>
        <div className='hero has-background-nude-light'>
          <div className='hero-body'>
            <div className='container content has-text-centered'>
              <div className='columns is-centered'>
                <div className='column is-6 is-full-touch'>
                  <FontAwesomeIcon
                    icon={faGifts}
                    className='has-text-nude is-margin-bottom-4'
                    size='2x'
                  />
                  <div className='is-margin-bottom-4'>
                    <h1 className='has-text-nude is-size-4 white-space-normal-mobile is-margin-top-0'>
                      {i18n.securityForcesBenefits.subtitle}
                    </h1>
                  </div>
                  <p className='is-margin-bottom-6'>
                    {i18n.securityForcesBenefits.preview}
                  </p>
                  <p>
                    <Link
                      to='/academy/security-forces-benefits/'
                      className='button is-white'
                    >
                      {i18n.securityForcesBenefits.previewCTA}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      <div className='hero'>
        <div className='hero-body is-padding-bottom-0 is-padding-top-0'>
          <hr className='has-background-nude-light' />
        </div>
      </div>
      <ScrollOnAnimation>
        <div id='academy' className='hero'>
          <div className='hero-body is-padding-bottom-0'>
            <div className='container content has-text-centered'>
              <h3 className='has-text-nude'>
                {i18n.academy.product.transition}
              </h3>
              <ScrollOnAnimation>
                <div className='columns is-centered is-vcentered is-margin-top-6'>
                  <div className='column is-full-real-mobile is-12-tablet is-10-desktop is-8-widescreen'>
                    <figure
                      className='image is-16by9'
                      style={{ marginRight: 0, marginLeft: 0 }}
                    >
                      <iframe
                        className='has-ratio'
                        width='640'
                        height='360'
                        src={`https://player.vimeo.com/video/932036041?h=826545297f&badge=0&autopause=0player_id=0&app_id=58479`}
                        frameBorder='0'
                        allow='autoplay; fullscreen; picture-in-picture'
                        ref={iframeRef}
                      />
                    </figure>
                    <script src='https://player.vimeo.com/api/player.js'></script>
                  </div>
                </div>
              </ScrollOnAnimation>
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      {[
        i18n.academy.product.bullets.slice(0, 3),
        i18n.academy.product.bullets.slice(3, 6),
        i18n.academy.product.bullets.slice(6, 9),
        i18n.academy.product.bullets.slice(9, 12)
      ].map((bullets, index) => (
        <div key={index} className='hero'>
          <div className='hero-body'>
            <div className='container content'>
              <div
                className={clsx(
                  'columns is-mobile is-multiline is-centered is-vcentered',
                  {
                    'direction-row-reverse': index % 2 === 1
                  }
                )}
              >
                <div
                  className={clsx(
                    'column is-full-mobile is-8-tablet is-6-desktop',
                    {
                      'is-offset-1-widescreen': index % 2 === 1
                    }
                  )}
                >
                  {bullets.map((bullet) => (
                    <ScrollOnAnimation key={bullet.title}>
                      <div className='columns is-flex-direction-column-touch'>
                        <div className='column is-narrow has-text-centered-touch is-padding-bottom-0'>
                          <FontAwesomeIcon
                            icon={faHeart}
                            className='has-text-nude is-size-7 is-relative'
                            style={{ top: 4 }}
                          />
                        </div>
                        <div className='column'>
                          <h4 className='has-text-centered-touch'>
                            {bullet.title}
                          </h4>
                          <p>{bullet.description}</p>
                        </div>
                      </div>
                    </ScrollOnAnimation>
                  ))}
                </div>
                <ScrollOnAnimation>
                  <div
                    className={clsx(
                      'column is-full-real-mobile is-10-mobile is-7-tablet is-6-desktop is-5-widescreen is-margin-top-4-touch',
                      {
                        'is-offset-1-widescreen': index % 2 === 0
                      }
                    )}
                  >
                    <GatsbyImage
                      image={getImage(PRODUCT_IMAGE[index])}
                      title={i18n.academy.product.images[index].title}
                      alt={i18n.academy.product.images[index].alt}
                      className='live-image'
                      style={{
                        maxWidth: getImage(PRODUCT_IMAGE[index]).width,
                        margin: '0 auto' // used to center the image
                      }}
                      loading='eager'
                    />
                    <div className='is-flex justify-center content'>
                      <p className='has-text-centered has-text-gold-black'>
                        {i18n.academy.product.images[index].title}
                      </p>
                    </div>
                  </div>
                </ScrollOnAnimation>
              </div>
            </div>
          </div>
          {index === 1 && (
            <ScrollOnAnimation>
              <div className='hero-body has-text-centered content'>
                <Link
                  to='/academy/courses/'
                  className='button is-nude is-medium'
                >
                  {i18n.academy.callToAction1}
                </Link>
              </div>
            </ScrollOnAnimation>
          )}
          {index === 3 && (
            <ScrollOnAnimation>
              <div className='hero-body has-text-centered content'>
                <Link
                  to='/academy/courses/'
                  className='button is-nude is-medium'
                >
                  {i18n.academy.callToAction}
                </Link>
              </div>
            </ScrollOnAnimation>
          )}
        </div>
      ))}
      <AcademyExtras includeAcademyVideo={false} product={product} />
    </>
  )
}

const Academy = ({ location: { pathname } }) => {
  const product = {
    category: i18n.academy.metadata.title,
    name: i18n.academy.metadata.title
  }
  const data = useStaticQuery(AcademyQuery)
  return (
    <Layout product={product}>
      <Seo
        metadata={i18n.academy.metadata}
        pathname={pathname}
        og={{
          image: getSrc(data.academyHero),
          alt: i18n.academy.seoImageAlt
        }}
        schemaMarkup={{
          breadcrumbs: [{ name: i18n.header.academy, path: '/academy/' }]
        }}
      />
      <AcademyTemplate pathname={pathname} data={data} product={product} />
    </Layout>
  )
}

export default Academy
